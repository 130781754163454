import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/index.vue";
import Mine from "../views/mine/index.vue";

Vue.use(VueRouter);

const routes = [{
        path: "*",
        redirect: "/",
    },
    {
        path: "/",
        name: "Home",
        component: Home,
        meta: {
            layout: "home",
            data: "home",
            requiresAuth: true,
        },
    },
    {
        path: "/game/sport",
        name: "sport",
        meta: {
            layout: "game",
            data: "sport",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/game/sport.vue"),
    },
    {
        path: "/game/realbet",
        name: "realbet",
        meta: {
            layout: "game",
            data: "realbet",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/game/sport.vue"),
    },
    {
        path: "/game/chess",
        name: "chess",
        meta: {
            layout: "game",
            data: "chess",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/game/sport.vue"),
    },
    {
        path: "/game/gaming",
        name: "gaming",
        meta: {
            layout: "game",
            data: "gaming",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/game/sport.vue"),
    },
    {
        path: "/game/lottery",
        name: "lottery",
        meta: {
            layout: "game",
            data: "lottery",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/game/sport.vue"),
    },
    {
        path: "/game/egame",
        name: "egame",
        meta: {
            layout: "game",
            data: "egame",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/game/egame.vue"),
    },
    {
        path: "/game/fishing",
        name: "fishing",
        meta: {
            layout: "game",
            data: "fishing",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/game/fishing.vue"),
    },
    {
        path: "/game/myGame",
        name: "myGame",
        meta: {
            layout: "myGame",
            data: "myGame",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/game/myGame.vue"),
    },
    {
        path: "/user/login",
        name: "login",
        meta: {
            head: true,
            footer: true,
            data: "login",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/login.vue"),
    },
    {
        path: "/user/register",
        name: "register",
        meta: {
            head: true,
            footer: true,
            data: "register",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/login.vue"),
    },
    {
        path: "/activity/discount",
        name: "activity",
        meta: {
            layout: 'discount',
            data: "discount",
            requiresAuth: true,
        },
        component: () =>
            import ("../views/activity/discount.vue"),
    },
    {
        path: "/agent",
        name: "agent",
        meta: {
            data: "agent",
            head: true,
            footer: true,
            requiresAuth: true,
        },
        component: () =>
            import ("../views/agent.vue"),
    },
    {
        path: "/sponsor/madrid",
        name: "sponsor",
        meta: {
            layout: "sponsor",
            data: "madrid",
            head: false,
            footer: true,
            requiresAuth: true,
        },
        component: () =>
            import ("../views/sponsor/madrid.vue"),
    },
    {
        path: "/download",
        name: "download",
        meta: {
            data: "download",
            layout: "download",
            head: false,
            footer: true,
            requiresAuth: true,
        },
        component: () =>
            import ("../views/download.vue"),
    },
    {
        path: "/activity/bettingGift",
        name: "bettingGift",
        meta: {
            data: "bettingGift",
            layout: "bettingGift",
        },
        component: () =>
            import ("../views/activity/bettingGift.vue"),
    },
    {
        path: "/activity/activityLandPage",
        name: "activityLandPage",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import ("../views/activity/activityLandPage.vue"),
    },
    {
        path: "/activity/activityLandPageFl",
        name: "activityLandPageFl",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import ("../views/activity/activityLandPageFl.vue"),
    },
    {
        path: "/mine",
        component: Mine,
        redirect: "/mine/userInfo",
        children: [{
                path: "/mine/userInfo",
                name: "userInfo",
                meta: {
                    isShow: true,
                },
                component: () =>
                    import ("../views/mine/userInfo.vue"),
            },
            {
                path: "/mine/vip",
                name: "vip",
                meta: {
                    isShow: false,
                },
                component: () =>
                    import ("../views/mine/vip.vue"),
            },
            {
                path: "/mine/details",
                name: "details",
                meta: {
                    isShow: false,
                },
                component: () =>
                    import ("../views/mine/details.vue"),
            },
            {
                path: "/record/welfare",
                name: "welfare",
                component: () =>
                    import ("../views/record/welfare.vue"),
            }, {
                path: "/rebate/index",
                name: "rebate",
                component: () =>
                    import ("../views/mine/rebate.vue"),
            },
            {
                path: "/record/transRecord",
                name: "transRecord",
                component: () =>
                    import ("../views/record/transRecord.vue"),
            },
            {
                path: "/record/betRecord",
                name: "betRecord",
                component: () =>
                    import ("../views/record/betRecord.vue"),
            },
            {
                path: "/record/giftRecord",
                name: "giftRecord",
                component: () =>
                    import ("../views/record/giftRecord.vue"),
            },
            {
                path: "/finance/deposit",
                name: "deposit",
                meta: {
                    isShow: true,
                },
                component: () =>
                    import ("../views/finance/deposit.vue"),
            },
            {
                path: "/finance/introTutorial",
                name: "help",
                component: () =>
                    import ("../views/finance/introTutorial.vue"),
                meta: {
                    requiresAuth: true,
                },
            },
            {
                path: "/finance/transfer",
                name: "transfer",
                meta: {
                    isShow: true,
                },
                component: () =>
                    import ("../views/finance/transfer.vue"),
            },
            {
                path: "/finance/withdrawal",
                name: "withdrawal",
                meta: {
                    isShow: true,
                },
                component: () =>
                    import ("../views/finance/withdrawal.vue"),
            },
            {
                path: "/finance/wallet",
                name: "wallet",
                meta: {
                    isShow: true,
                },
                component: () =>
                    import ("../views/finance/wallet.vue"),
            },
            {
                path: "/mail",
                name: "mail",
                component: () =>
                    import ("../views/mine/mail.vue"),
            },
            {
                path: "/feedback/list",
                name: "feedbackList",
                meta: {
                    isShow: true,
                },
                component: () =>
                    import ("../views/mine/feedbackList.vue"),
            },
            {
                path: "/feedback/detail",
                name: "feedbackDetail",
                meta: {
                    isShow: true,
                },
                component: () =>
                    import ("../views/mine/feedbackDetail.vue"),
            },
            {
                path: "/finance/banks",
                name: "banks",
                meta: {
                    requiresAuth: true,
                },
                component: () =>
                    import ("../views/finance/banks.vue"),
            },
            {
                path: "/mine/password",
                name: "password",
                meta: {
                    isShow: false,
                },
                component: () =>
                    import ("../views/mine/password.vue"),
            },
            {
                path: "/mine/withdrawPassword",
                name: "withdrawPassword",
                meta: {
                    isShow: false,
                },
                component: () =>
                    import ("../views/mine/password.vue"),
            },
            {
                path: "/mine/contacts",
                name: "contacts",
                meta: {
                    isShow: false,
                },
                component: () =>
                    import ("../views/mine/contacts.vue"),
            },
            {
                path: "/mine/addBalance",
                name: "addBalance",
                meta: {
                    isShow: false,
                },
                component: () =>
                    import ("../views/mine/addBalance.vue"),
            },
        ],
    },
    {
        path: "/customer/main",
        name: "main",
        meta: {
            head: true,
            footer: true,
            data: "login",
        },
        component: () =>
            import ("../views/contact.vue"),
    },
    {
        path: "/help",
        name: "Help",
        component: () =>
            import ("../views/help/index.vue"),
        meta: {
            requiresAuth: true,
        },
    },
    {
        path: "/inviteFriends",
        name: "invite",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import ("../views/activity/invite.vue"),
    },
    {
        path: "/inviteFriends/deatal",
        name: "inviteFriends",
        meta: {
            requiresAuth: true,
        },
        component: () =>
            import ("../views/activity/inviteFriend.vue"),
    },
    {
        path: "/vip/introduce",
        name: "introduce",
        meta: {
            isShow: false,
        },
        component: () =>
            import ("../views/mine/vipIntroduce.vue"),
    },
    {
        path: "/brand",
        name: "brand",
        meta: {
            layout: "brand",
            data: "brand",
            head: false,
            footer: true,
            requiresAuth: true,
        },
        component: () =>
            import ("../views/brand/index.vue"),
    }
];

const router = new VueRouter({
    mode: "hash",
    base: process.env.BASE_URL,
    routes,
});
// 判断将去路由是否存在
let checkRoute = (path) => {
    let len = router.getRoutes().filter((item) => item.path == path).length;
    if (len) return true;
    else return false;
};

const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
    return originalPush.call(this, location).catch((err) => err);
};

// 检测是否登录
router.beforeEach((to, from, next) => {
    const token = localStorage.getItem("AppToken");
    if (to.meta.requiresAuth) {
        next();
    } else {
        if (token) {
            next();
        } else {
            // console.log(to.path);
            next("/");
        }
    }
});

export default router;